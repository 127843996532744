import { useLayoutEffect, useRef, useState } from "react"
import "moment/locale/es"
import moment from "moment/moment"

const isBrowser = typeof window !== `undefined`

const getScrollPosition = ({ element, useWindow }) => {
    if (!isBrowser) return { x: 0, y: 0 }

    const target = element ? element.current : document.body,
        position = target.getBoundingClientRect()

    return useWindow
        ? { x: window.scrollX, y: window.scrollY }
        : { x: position.left, y: position.top }
}

export const useScrollPosition = (effect, deps = [], element, useWindow, wait) => {
    const position = useRef(getScrollPosition({ useWindow }))
    let throttleTimeout = null

    const callBack = (event) => {
        const currentPosition = getScrollPosition({ element, useWindow })

        effect({ previousPosition: position.current, currentPosition: currentPosition }, event)
        position.current = currentPosition
        throttleTimeout = null
    }

    useLayoutEffect(() => {
        const handleScroll = (e) => {
            if (wait && !throttleTimeout) throttleTimeout.current = setTimeout(() => callBack(e), wait)
            else callBack(e)
        }

        window.addEventListener(`scroll`, handleScroll)

        return () => window.removeEventListener(`scroll`, handleScroll)
    }, deps)
}

export const documentReady = (fn) => {
    if (!isBrowser || !window.document) {
        return
    }

    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1)
    } else {
        document.addEventListener("DOMContentLoaded", fn)
    }
}

/**
 * @param {*} portalId
 * @param {string} formId
 * @param {*} target
 * @param {string} region
 * @param {any} data
 */
export const initializeHubspotForm = (portalId, formId, target, region = "na1", data = null) => {
    if (!isBrowser) {
        return
    }

    window.jQuery = window.jQuery || (() => ({
        // these are all methods required by HubSpot
        change: () => {
        },
        trigger: () => {
        }
    }))

    documentReady(() => {
        if (isBrowser && "hbspt" in window) {
            window.hbspt.forms.create({
                region: region,
                portalId: portalId,
                formId: formId,
                target: target,
                onFormReady: function($form) {
                    if (data) {
                        for (let key in data) {
                            document.querySelectorAll(`input[name="${key}"]`)[0].value = data[key]
                        }
                    }
                }
            })
        } else {
            setTimeout(() => initializeHubspotForm(portalId, formId, target, region), 500)
        }
    })
}

export const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue)

    const handleChange = e => {
        setValue(typeof e === "object" ? e.target.value : e)
    }

    return {
        value,
        onChange: handleChange
    }
}

export function setCookie(cname, cvalue, exdate) {
    if (typeof window === "undefined" || !window.document) {
        return
    }

    document.cookie = cname + "=" + cvalue + ";expires=" + exdate.toString() + ";path=/"
}

export function deleteCookie(name) {
    if (typeof window === "undefined" || !window.document) {
        return
    }
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"

}

export function getCookie(cname) {
    if (typeof window === "undefined" || !window.document) {
        return
    }

    const name = cname + "="
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(";")

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]

        while (c.charAt(0) === " ") {
            c = c.substring(1)
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }

    return null
}

export function getPostContentFirstText(post) {
    if (typeof window === "undefined" || !window.document) {
        return
    }

    const items = post.content.filter((item) => item.strapi_component === "blog.content-rich-text")

    if (items.length === 0) {
        return ""
    }

    return getFirstText(items[0].text)
}

export function getFirstText(html) {
    if (typeof window === "undefined" || !window.document) {
        return
    }

    let tmp = document.createElement("DIV")

    tmp.innerHTML = html

    return tmp.textContent || tmp.innerText || ""
}

export function stringToSlug(str) {
    str = str.replace(/^\s+|\s+$/g, "")
    str = str.toLowerCase()

    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
    const to = "aaaaeeeeiiiioooouuuunc------"

    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
    }

    str = str.replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-")

    return str
}

export function getStringFromElement(obj) {
    let buf = ""

    if (obj) {
        const type = typeof (obj)

        if (type === "string" || type === "number") {
            buf += obj
        } else if (type === "object") {
            let children = null

            if (Array.isArray(obj)) {
                children = obj
            } else {
                const props = obj.props

                if (props) {
                    children = props.children
                }
            }

            if (children) {
                if (Array.isArray(children)) {
                    children.forEach(function(o) {
                        buf += getStringFromElement(o)
                    })
                } else {
                    buf += getStringFromElement(children)
                }
            }
        }
    }

    return buf
}

export function isYoutubeUrl(url) {
    return url.match(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/)
}

export function getYouTubeEmbedUrl(url) {
    if (!url.includes("embed")) {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/
        const match = url.match(regExp)

        return `https://www.youtube.com/embed/${match[8]}?&autoplay=1`
    }

    return url
}

export function getEventDateFormatted(event, lang, dateStartFormat = "D MMMM", dateEndFormat = "D MMMM") {
    if (moment(event.date_start).isSame(event.date_end)) {
        return moment(event.date_start).locale(lang).format(dateStartFormat)
    }

    if (moment(event.date_start).isSame(event.date_end, 'year')) {
        dateStartFormat = dateStartFormat.replace(/Y/g, '').trim()
    }

    if (moment(event.date_start).isSame(event.date_end, 'month')) {
        dateStartFormat = dateStartFormat.replace(/M/g, '').trim()
    }

    return `${moment(event.date_start).locale(lang).format(dateStartFormat)} - ${moment(event.date_end).locale(lang).format(dateEndFormat)}`
}

export const checkShowForm = (location) => {
    return (new URLSearchParams(location.search)).get("show-form") === "true";
}

export const FormStatus = {
    init: "init",
    sending: "sending",
    ok: "ok",
    ko: "ko"
}
